<template>
  <div>
  <TosListVuetify serviceName="yard" entityName="unitvisits" :headers="headers" >
    <template #customFilters="slotProps">
      <v-container fluid>
       <v-row>
          <v-col cols="3">
            <v-text-field  :label="tLabel('Unit number')" v-model="slotProps.data.objectCode" />
          </v-col>
          <v-col cols="3">
            <v-text-field  :label="tLabel('Unit type')" v-model="slotProps.data.unitTypeCode" />
          </v-col>
          <v-col cols="3">
            <v-text-field  :label="tLabel('Alternative Code')" v-model="slotProps.data.altCode" />
          </v-col>
          <v-col cols="3">
            <v-select  :label="tLabel('Position status')" v-model="slotProps.data.positionStatus" :items="positionStatus" />
          </v-col>
       </v-row>
      </v-container>
    </template>

    <template #item.action="slotProps">
        <div class="css-group-and-align-element css-centered">
            <v-btn icon title="stampa" @click="stampaInterchange(slotProps.data.id)" >
              PDF
            </v-btn>
           
            </div>
    </template>

    <template v-slot:editF="slotProps">
       <v-container fluid>
        <v-row>
          <v-col>
            <v-text-field  :label="tLabelRequired('Unit number')" v-model="slotProps.data.objectCode" :error-messages="slotProps.errors.objectCode" :title="tLabel('codice univoco della unit che la identifica all’interno dello yard. Può essere il codice di un container, la targa di una motrice o di un rimorchio, il numero di passaporto, il callsign di una nave')" />
          </v-col>
          <v-col>
            <v-text-field  :label="tLabelRequired('Unit type')" v-model="slotProps.data.unitTypeCode" :error-messages="slotProps.errors.unitTypeCode" :title="tLabel('codice tipo uti')" />
          </v-col>
          <v-col>
            <v-text-field  :label="tLabel('Alternative number')" v-model="slotProps.data.altCode" :error-messages="slotProps.errors.altCode" :title="tLabel('unit code alternativo')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-text-field  :label="tLabel('Quantity')" v-model="slotProps.data.qty" :rules="[rules.numeric]" :error-messages="slotProps.errors.qty"  :title="tLabel('numero di colli. Rappresenta il numero di sotto-unità accorpate nella unit, non movimentabili singolarmente.')" />
          </v-col>
          <v-col cols="2">
            <v-text-field  :label="tLabel('Gross weight')" v-model="slotProps.data.grossweight" :rules="[rules.numeric]" :error-messages="slotProps.errors.grossweight"  :title="tLabel('peso lordo totale, che comprende unit e contenuto al suo interno.')" />
          </v-col>
          <v-col cols="2">
            <v-text-field  :label="tLabel('Last position')" v-model="slotProps.data.positionCode" :error-messages="slotProps.errors.positionCode"  :title="tLabel('codifica dell’ultima posizione conosciuta.')" />
          </v-col>
          <v-col>
            <v-select :label="tLabelRequired('Position status')"  v-model="slotProps.data.positionStatus" :items="positionStatus" :rules="[rules.required]" :error-messages="slotProps.errors.positionStatus"  />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field  :label="tLabel('Primary code')" v-model="slotProps.data.primaryObjectCode"  :error-messages="slotProps.errors.primaryObjectCode" 
            :title="tLabel('Unit code della visita principale a cui la visita corrente è legata e con cui si muove come unica unità. Es. il pianale di un truck è legata alla visita della motrice. Una unit su mafi è legata alla visita del mafi.')" />
          </v-col>
          <v-col>
            <v-select :label="tLabel('Status')"  v-model="slotProps.data.visitStatus" :items="visitStatus" :error-messages="slotProps.errors.visitStatus" :title="tLabel('stato della visita')" />
          </v-col>
          <v-col>
            <v-select :label="tLabelRequired('Custom status')"  v-model="slotProps.data.customsStatus" :items="customsStatus" :rules="[rules.required]" :error-messages="slotProps.errors.customsStatus"  />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field  :label="tLabel('Goods')" v-model="slotProps.data.goods"  :error-messages="slotProps.errors.goods" :title="tLabel('codifica testuale della merce contenuta')" />
          </v-col>
          <v-col>
            <v-text-field  :label="tLabel('ExtRef')" v-model="slotProps.data.extRef"  :error-messages="slotProps.errors.extRef" :title="tLabel('riferimento esterno della visita')" />
          </v-col>
          <v-col>
            <v-text-field  :label="tLabel('GroupCode')" v-model="slotProps.data.groupCode"  :error-messages="slotProps.errors.groupCode" :title="tLabel('codice generico di raggruppamento')" />
          </v-col>
          <v-col>
            <v-text-field  :label="tLabel('lot')" v-model="slotProps.data.lot"  :error-messages="slotProps.errors.lot" :title="tLabel('codice lotto')" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field  :label="tLabel('description')" v-model="slotProps.data.description"  :error-messages="slotProps.errors.description" :title="tLabel('descrizione testuale')" />
          </v-col>
          <v-col cols="12">
            <v-text-field  :label="tLabel('remarks')" v-model="slotProps.data.remarks"  :error-messages="slotProps.errors.remarks" :title="tLabel('note')" />
          </v-col>
        </v-row>

        <v-card>       
          <v-card-title class="text-uppercase font-weight-regular py-2 my-2 panelheader">
            {{ tLabel('Entrance') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-radio-group :label="tLabel('Transport Type')" row v-model="slotProps.data.handlingInTransportType"  >
                  <v-radio label="RAIL" value="TRA"></v-radio>
                  <v-radio label="GATE" value="TRU"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="3">
                <DateTimeAdapter :label="tLabel('Date / time ')" v-model="slotProps.data.handlingInExecDate" />
              </v-col>
            </v-row>
            <v-row v-if="slotProps.data.handlingInTransportType == 'TRA'">
              <v-col cols="4">
                <v-text-field :label="tLabel('Voyage code')" />
              </v-col>
              <v-col cols="4">
                <v-text-field :label="tLabel('Wagon N.')"  />
              </v-col>
            </v-row>      
            <v-row v-if="slotProps.data.handlingInTransportType == 'TRU'">
              <v-col cols="4">
                <v-text-field :label="tLabel('Mission Id.')"  />
              </v-col>
              <v-col cols="4">
                <v-text-field :label="tLabel('Truck code')"  />
              </v-col>
            </v-row>
            <v-row v-if="slotProps.data.handlingInTransportType == 'TRU'">
              <v-col cols="12">
                ALL RORO DATA TO INSERT
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

       </v-container>
    </template>
  </TosListVuetify>

</div>

</template>


<script>
import TosListVuetify from '../../../components/anagrafiche/TosListVuetify';
import FormatsMixins from "../../../mixins/FormatsMixins";
import YardEnumMixin from "../../../mixins/yard/EnumMixin";
import DateTimeAdapter from "../../../components/anagrafiche/DateTimeAdapter";
import railsMixins from '../../../components/rails/mixins/railsMixins';

export default {
  name: "UnitVisits",
  data() {
    return {
      item: null,
      headers: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Unit number", value: "objectCode" },
        { text: "Unit type", value: "unitTypeCode" },
        { text: "Qty", value: "qty" },
        { text: "Gross Weight", value: "grossweight" },
        { text: "Goods", value: "goods" },
        { text: "Pos. status", value: "positionStatus" },
      ],
    };
  },
  service: null,
  mounted(){
  },
  
  methods: {
    async prepareItem(data) {
      if(data.id==null){
        data.customsStatus='D';
        data.role="UNT";
        data.sysType="UNT";

      }
    },
    async stampaInterchange(id){
      try{
        const _callConfig = {
        responseType: 'blob',
        headers: {
             'Content-Type': "application/pdf",
             'Accept': '*/*',
             'Pragma': 'no-cache'
           }
         };
        var url = `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeInByVisitId/`+id;
        
        const response = await this.$api.get(url, _callConfig);
        const blob = new Blob([response],  { type: "application/pdf;charset=ANSI" });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement('a');
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute('download', 'Interchange3.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch(e){
        this.showError('Generazione Interchange non andata a buon fine');
        console.log(e);
      }
    },
  },
  components: {TosListVuetify, DateTimeAdapter},
  mixins: [FormatsMixins, YardEnumMixin, railsMixins],
};
</script>
